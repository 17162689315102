import { FunctionComponent, ReactNode } from 'react';

// Own
import sadEmoji from './sad-emoji.png';

const Errors: FunctionComponent<Props> = ({ className, title, description, children }) => {
  const emptyContent = children ? '' : 'view__empty-content';

  return (
    <div className={className}>
      <div className={`view__content ${emptyContent}`}>
        <header className="view__content-header ">
          {title} <img src={sadEmoji} alt="emoji" />
        </header>
        <main className="view__content-main">{description}</main>
        {children}
      </div>
    </div>
  );
};

interface Props {
  className?: string;
  title: string;
  description: string;
  children?: ReactNode;
}

export default Errors;

export interface Props {
  className?: string;
  viewType: ViewType;
  action?: () => void;
}

export enum ViewType {
  ERROR = 'error',
  NO_AVAILABLE = 'noAvailable',
  FEED_ERROR = 'feedError',
}

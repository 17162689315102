import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

//Own
import { ReactComponent as LogoSosafe } from 'assets/logo-sosafe.svg';

const Loader: FunctionComponent<Props> = ({ className, showLogo, fontSize }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: fontSize || 46 }} spin />;

  return (
    <div className={className}>
      {!!showLogo && <LogoSosafe />}
      <div className="loader__spin-indicator-container">
        <Spin indicator={antIcon} />
      </div>
    </div>
  );
};

export default styled(Loader)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;

  .loader__spin-indicator-container {
    padding-top: 4%;
  }
`;

interface Props {
  className?: string;
  showLogo?: boolean;
  fontSize?: number;
}

import React from 'react';
import ReactDOM from 'react-dom';
import App from 'app';
import './antd.css';
import './app.css';
import 'utils/message/styles.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

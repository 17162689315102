import styled from 'styled-components';

// Own
import Errors from './errors.component';

export default styled(Errors)`
  font-family: Inter UI, Helvetica, Arial, sans-serif;
  font-style: normal;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5c5c5c;
  border-radius: 8px;
`;

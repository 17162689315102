import { useState } from 'react';

export const useQueryParams = (paramKey: string) => {
  const [paramValue] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get(paramKey) || '';
  });

  return paramValue;
};

export enum WidgetModules {
  None = 'none',
  Report = 'report',
  Feed = 'feed',
}

export const Modules: Record<WidgetModules, Module> = {
  [WidgetModules.None]: {
    route: WidgetModules.None,
    loader: {},
  },
  [WidgetModules.Report]: {
    route: WidgetModules.Report,
    loader: {
      showLogo: true,
    },
  },
  [WidgetModules.Feed]: {
    route: WidgetModules.Feed,
    loader: {
      showLogo: false,
      fontSize: 76,
    },
  },
};

export interface Module {
  route: WidgetModules;
  loader: Loader;
}

export interface Loader {
  showLogo?: boolean;
  fontSize?: number;
}

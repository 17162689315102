import styled from 'styled-components';

// Own
import Errors from './error.component';

export default styled(Errors)`
  font-family: Inter UI, Helvetica, Arial, sans-serif;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5c5c5c;
  border-radius: 8px;

  .view__content {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: center;
    background-color: red;
    text-align: center;
    position: absolute;
    background: #ffffff;
    backdrop-filter: blur(54.3656px);
    border-radius: 14px;
  }

  .view__content-header {
    display: flex;
    align-items: center;
    padding: 12px;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.408px;
    color: #000000;

    img {
      margin-left: 0.25rem;
      width: 22px;
      height: 22px;
      object-fit: contain;
    }
  }

  .view__content-main {
    padding: 10px;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.078px;
    color: #787878;
  }

  .view__content-footer {
    width: 100%;
    padding: 10px;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: -0.41px;
    color: #f54286;
    cursor: pointer;
    ${({ children }) => {
      return children ? 'border-top: 1px solid #efefef;' : '';
    }}
  }

  .view__empty-content {
    padding: 28px 24px 40px 24px;
  }

  @media (max-width: 730px) {
    width: 60%;
  }
`;

import { FunctionComponent } from 'react';

// Own
import useLang from 'utils/use-lang';
import { LOCALES } from './locales';
import { Props } from './types';
import Error from 'components/error';

const Errors: FunctionComponent<Props> = ({ className, viewType, action }) => {
  const lang = useLang();
  const content = LOCALES[viewType][lang];

  return (
    <div className={className}>
      <Error title={content.title} description={content.description}>
        {action && (
          <footer className="view__content-footer">
            <span onClick={() => action?.()}>{content.action}</span>
          </footer>
        )}
      </Error>
    </div>
  );
};

export default Errors;

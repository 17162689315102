import { FunctionComponent, lazy, Suspense } from 'react';

// Own
import { WidgetModules } from 'core/widgets-types';
import Loader from 'components/loader';
import { ViewType } from 'core/report/errors/types';
import Errors from 'core/report/errors';
import { MODULE, setCurrentModule } from 'current-module';

const Report = lazy(() => import('core/report/page'));
const Feed = lazy(() => import('core/feed'));
setCurrentModule();

const renderRoute = () => {
  switch (MODULE.route) {
    case WidgetModules.Report:
      return <Report />;
    case WidgetModules.Feed:
      return <Feed />;
    default:
      return <Errors viewType={ViewType.NO_AVAILABLE} />;
  }
};

const App: FunctionComponent = () => {
  return <Suspense fallback={<Loader {...MODULE?.loader} />}>{renderRoute()}</Suspense>;
};

export default App;

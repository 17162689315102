export const LOCALES: Record<string, any> = {
  error: {
    es: {
      title: 'Ups',
      description: 'Lo sentimos, algo salió mal. ¡Por favor recarga!',
      action: 'Recargar',
    },
    en: {
      title: 'Oops',
      description: 'We are sorry, something went wrong. Please reload!',
      action: 'Reload',
    },
    fr: {
      title: 'Oops',
      description: "Nous sommes désolés, quelque chose s'est mal passé. Veuillez recharger!",
      action: 'Recharger',
    },
    pt: {
      title: 'Opa',
      description: 'Lamentamos, algo deu errado. Por favor recarregue!',
      action: 'Recarregar',
    },
  },
  noAvailable: {
    es: {
      title: 'Ups',
      description: 'Página no encontrada. El enlace podría estar roto.',
      action: '',
    },
    en: {
      title: 'Oops',
      description: 'Page not found. The link may be broken.',
      action: '',
    },
    fr: {
      title: 'Oups',
      description: 'Page non trouvée. Le lien peut être rompu.',
      action: '',
    },
    pt: {
      title: 'Opa',
      description: 'Página não encontrada. O link pode ter sido excluído.',
      action: '',
    },
  },
  feedError: {
    es: {
      title: 'Ups',
      description: 'Hubo un error al intentar cargar los reportes. ¡Por favor, inténtalo de nuevo!',
      action: 'Intentar nuevamente',
    },
    en: {
      title: 'Oops',
      description: 'An error has occurred while trying to load the reports. Please try again!',
      action: 'Try again',
    },
    fr: {
      title: 'Oups',
      description:
        "Une erreur s'est produite lors de la tentative de chargement \
        des rapports. Veuillez réessayer!",
      action: 'Réessayer',
    },
    pt: {
      title: 'Opa',
      description: 'Ocorreu um erro ao tentar carregar os relatórios. Por favor, tente novamente!',
      action: 'Tente novamente',
    },
  },
};
